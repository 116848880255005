.Contact--Form {
    display: block;
    padding-bottom: 0 !important;
    
    &-Background {
        background-blend-mode: hue;
        background-color: rgba($cc-dark-grey, 0.94);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        padding-bottom: u(100);
        position: relative;

        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            &:after{
                background: transparentize(darken($cc-dark-grey, 8), .10);
                bottom: 0;
                content: "";
                position: absolute;
                top: 0;
                width: 100%;
            }
        }
    
    }

    .Container {
        position: relative;
        z-index: 2;
    }
    
    .Contact--form-header {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: u(80, 0, 52);

        * {
            color: $white;
            max-width: u(475);
        }
    }

    .Contact--form-body {
        display: flex;
        justify-content: center;
    }
}

.Contact--map {
    height: 100%;
    min-height: u(400);
}
