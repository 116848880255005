/* Header */
.Header {
    background-color: $white;
    padding-top: u($spacing-unit);
    padding-bottom: u($spacing-unit);
    position: relative;
    z-index: 3;

    > .Container {
        display: flex;
        align-items: center;
    }

    // ToggleNav
    @include mq($until: $viewport--md - 1) {

        &::after {
            @include transition(#{opacity, visibility});
            background-color: $black;
            content: '';

            position: fixed;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            z-index: -1;

            html:not(.show-nav) & {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}
