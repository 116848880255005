.FullWidthImage {
    height: u(400);
    overflow: hidden;
    position: relative;

    img {
        height: 100%;
        left: 50%;
        object-fit: cover;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
    } 
}