.Apply {
    background-blend-mode: hue;
    background-color: rgba($cc-dark-grey, 0.90);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding-bottom: u(120);
    padding-top: u(40);
    position: relative;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        &:after{
            background: transparentize(darken($cc-dark-grey, 8), .10);
            bottom: 0;
            content:'';
            position: absolute;
            top: 0;
            width: 100%;
        }
    }

    &--header {
        display: flex;
        justify-content: center;
        padding: u(80, 0, 52);
        
        * {
            color: $white;
            max-width: u(475);
        }
    }

    &--body {
        display: flex;
        justify-content: center;
    }

    .Container {
        position: relative;
        z-index: 2;
    }
}