/* Footer */
.Footer {
    background-color: $footer-background-color;
    color: $white;

    // Top
    &-top {
        padding-top: u($spacing-unit * 3.5);
        padding-bottom: u($spacing-unit * 4.5);
        
        @include mq($until: $viewport--sm) {
            padding-bottom: u($spacing-unit);
        }

           
        .Establishment {
            width: 50%;
            float: left;

            @include mq($from: $viewport--lg - 1) {
                width: 100%;
            }
        }

        .Logo {
            svg {
                width: 100px;
            }
        }

        label {
            display: block;
            color: $cc-primary;
            text-transform: uppercase;
            margin-bottom: u($line-height);
        }

        a {
            color: $white;
        }
    }

    // Bottom
    &-bottom {
        border-top: 1px solid rgba($black, .1);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: u($spacing-unit--sm);
        padding-bottom: u($spacing-unit--sm);
    }

    // Copyright
    &-copyright {
        display: inline-flex;
        align-items: center;
        text-decoration: none;

        &,
        &:active,
        &:focus,
        &:hover {
            color: currentColor;
        }

        svg {
            fill: currentColor;
            display: block;
            margin-left: u($spacing-unit--sm);
            height: u(25px);
            width: u(20px);
        }
    }
}
