.Accordion {
    margin-bottom: u(60);
    padding-top: u(60);

    .Accordion--item {
        @include transition(box-shadow);
        margin-bottom: u($line-height);
        margin-left: u(1);
    }

    .is-active {
        box-shadow: 0 u(3) u(6) rgba($cc-grey, .15);

        .Question--wrapper {
            .Accordion--icon-wrapper {
                background-color: $cc-secondary;

                .Accordion--icon {
                    transform: rotate(135deg);

                    svg {
                        @include dimensions(12);
                        
                        path {
                            fill: $cc-black;
                        }
                    }
                }
            }
        }
    }

    .Question {
        &--content {
            background-color: $white;
            border-bottom: u(1) solid $cc-grey--hover;
            border-top: u(1) solid $cc-grey--hover;
            font-family: $font-roboto-slab;
            margin-bottom: 0;
            padding: u($line-height / 2)  u(60)  u($line-height / 2)  u($line-height / 2);
        }

        &--wrapper {
            background-clip: border-box;
            background-color: #fff;
            border-left: u(1) solid $cc-grey--hover;
            border-right: u(1) solid $cc-grey--hover;
            color: $cc-dark-blue;
            display: flex;
            flex-direction: column;
            font-weight: 600;
            min-width: 0;
            position: relative;
            text-decoration: none;
            word-wrap: break-word;

            .Accordion--icon-wrapper {
                @include position(absolute, $top: 0, $right: 0);
                @include transition(background-color);
                border-left: u(1) solid $cc-grey--hover;
                height: 100%;
                width: u(50);

                .Accordion--icon {
                    @include position(relative);
                    @include transition(transform);
                    height: 100%;
                    width: 100%;

                    svg {
                        @include dimensions(10);
                        @include position(absolute, $top: 50%, $left: 50%);
                        transform: translate(-50%, -50%);
                        
                        path {
                            fill: $cc-dark-blue;
                        }
                    }
                }
            }
        }
    }

    .Answer {
        &--wrapper {
            display: none;
            overflow: hidden;
        }

        &--content {
            border-bottom: u(1) solid $cc-grey--hover;
            border-left: u(1) solid $cc-grey--hover;
            border-right: u(1) solid $cc-grey--hover;
            flex: 1 1 auto;
            padding: 1.25rem;
        }
    }
}