.IconTiles {
    background-color: $cc-light-grey;
    padding-bottom: u(20);
    padding-top: u(60);

    @include mq($until: $viewport--md) {
        padding-bottom: u(40);
    }

    &--item {
        align-items: center;
        background-color: $white;
        box-shadow: 0 3px 6px rgba($cc-dark-blue, 0.05);
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        padding: u(60) u(60);

        svg {
            height: u(60);
            margin-bottom: u(25);
        }
        
        h1, h2, h3, h4, h5, h6 {
            color: $cc-dark-grey;
            font-size: u(16);
            font-weight: 700;
        }
    }

    .Grid-cell {
        margin-bottom: u(40);

        @include mq($until: $viewport--md) {
            margin-bottom: u(20);
        }
    }
}