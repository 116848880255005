.LightGallery {
    .LightGallery--item {
        cursor: pointer;

        img {
            height: 100%;
            object-fit: cover;
            opacity: .85;
            width: 100%;

            &:hover {
                opacity: 1;
            }
        }
    }
}
