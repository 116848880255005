.HighlightedVacancies {
    background-color: $cc-primary;
    color: $white;
    display: flex;
    flex-direction: column;
    height: u(740);
    overflow: hidden;
    padding-top: u(60);

    .Grid {
        height: 100%;

        .Grid-cell {
            display: flex;
            flex-direction: column;
        }
    }

    .HighlightedVacancies--header {
        padding-left: u(80);
        padding-right: u(80);

        @include mq($until: $viewport--xl - 1) {
            padding-left: u(40);
            padding-right: u(40);
        }

        h2 {
            margin-bottom: u(40);
        }
    }

    .HighlightedVacancies--body {
        display: flex;
        flex-direction: column;
        flex-grow: 2;
        justify-content: flex-start;
        padding-left: u(80);
        padding-right: u(80);

        @include mq($until: $viewport--xl - 1) {
            padding-left: u(40);
            padding-right: u(40);
        }

        .HighlightedVacancies--item {
            border-bottom: u(1) solid $white;
            margin-bottom: u(25);
            padding-bottom: u(25);

            &:last-child {
                border-bottom: none;
                padding: 0 !important;
            }
            
            .HighlightedVacancies--item-summary {
                --max-lines: 2;
                max-height: calc(22px * var(--max-lines));
                overflow: hidden;
                position: relative;
            }
        }
    }

    .HighlightedVacancies--footer {
        background-color: $cc-grey;
        color: $white;
        font-weight: 500;
        text-decoration: none;
        text-transform: uppercase;
    }
}