/* Colors: Custom */

// Grey
$cc-grey:                           #4f4f4f !default;
$cc-light-grey:                     #f7f7f7;
// $cc-dark-grey:                      #444444;
$cc-dark-grey:                      #282828;
$cc-grey--hover:                    scale-color($cc-grey, $lightness: 70%);
$cc-black:                          #1a1a1a;
$cc-dark-blue:                      #1d222b;
$cc-dark-blue--hover:               scale-color($cc-dark-blue, $lightness: 30%);
$cc-primary:                        #03bedc;
$cc-main-blue:                      #3D96CE;
$cc-secondary:                      #ECCB45;