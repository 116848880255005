/* DigitalPulse */
.DigitalPulse {
    color: currentColor;
    display: flex;
    align-items: center;
    text-decoration: none;
    position: relative;
    margin-left: auto;
    height: 1.5em;

    &-icon,
    &-body {
        display: block;
        fill: currentColor;
    }

    // Label
    &-label {
        margin-right: .3em;
        flex-shrink: 0;
    }

    // Object
    &-object {
        background-color: $digitalpulse-background-color;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    // Icon
    &-icon {
        flex-shrink: 0;
        height: 1.5em * $digitalpulse-icon-ratio;
        transform: translateY(5%);
        width: 1.5em;
    }

    @include mq($viewport--lg) {

        &-object {
            @include transition(#{transform, right}, 300ms, ease-out);
            min-width: (1.5em * $digitalpulse-icon-ratio) * $digitalpulse-height-diff * $digitalpulse-text-ratio;
            position: relative;
            z-index: 1;

            position: absolute;
            top: 50%;
            right: 0;
            transform: scale(1.01) translateY(-50%); // Scale Firefox fix
        }

        &:hover .DigitalPulse-object {
            color: currentColor;
            right: 100%;
        }

        &-body {
            @include transition(width, 300ms, ease-out);
            margin-left: .3em;
            transform: translateY(12.5%);

            display: flex;
            justify-content: flex-end;
            height: (1.5em * $digitalpulse-icon-ratio) * $digitalpulse-height-diff;
            width: (1.5em * $digitalpulse-icon-ratio) * $digitalpulse-height-diff * $digitalpulse-text-ratio;
        }
    }
}
