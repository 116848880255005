/* Nav */

.show-nav {
    @include mq($until: $viewport--lg - 1) {
        .Header {
            &::after {
                background-color: $cc-dark-blue;
            }  

            .Logo {
                svg {
                    @include mq($until: $viewport--md) {
                        .textLogoHeader{
                            fill: $white;
                        }
                    }
                }
            }


            .Toggle-icon {
                path {
                    fill: $white;
                }
            }

            .Nav {

                .Nav-toggle {
                    color: $white;
                }

                .Nav-link {
                    color: $white;
                    margin-top: 8px;
                    margin-bottom: 8px;

                    &.Button {
                        margin-top: u(10);
                    }
                    
                    &:active,
                    &:focus,
                    &:hover {
                        color: $link-color--hover;
                    }

                    &.is-active {
                        color: $link-color--hover;

                        &:hover {
                            color: $link-color--hover;
                        }              
                    }

                    .Counter {
                        bottom: -6px;
                    }
                }
            }
        }
    }
}

.Nav {
    // Main
    &--main {
        margin-left: u($spacing-unit);

        .Nav-toggle {

            @include mq($until: $viewport--md - 1) {

                .show-nav & {
                    color: $white;
                }
            }
        }

        .Nav-list {

            @include mq($until: $viewport--md - 1) {
                color: $white;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                position: fixed;
                top: u($structure-header-height);
                right: 0;
                bottom: 0;
                left: 0;

                html:not(.show-nav) & {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }

        .Nav-item {
            position: relative;

            &:first-of-type {
                padding-left: 0px;
            }

            &::before {
                display: none;
            }

            >.Nav-link {
                &:not(.Button) {
                    padding: 25px 0;

                    @include mq($until: $viewport--md) {
                        padding: 0px 0;
                    }
                }

                &.Button {
                    margin: 15px 0;

                    @include mq($until: $viewport--md) {
                        margin: 10px 0;
                    }
                }
            }

            &:hover {
                .SubNav-list {
                    display: block;
                    opacity: 1;
                    padding: u(25);
                    width: auto;
                    height: auto;

                    @include mq($until: $viewport--md) {
                        display: none;
                    }
                }
            }

            .SubNav-list {
                display: none;
                box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
                top: 50px;
                width: 0;
                height: 0;
                opacity: 0;
                position: absolute;
                left: calc(50% + 25px);
                transform: translateX(-50%);
                background: $white;
                font-weight: 400;
                text-transform: none;
                letter-spacing: 0;
                line-height: 24px;
                padding: 0;
                min-width: 220px;
                list-style: none;
            }

            @include mq($until: $viewport--md - 1) {
                @include transition(#{opacity, transform}, #{300ms, 1s}, #{linear, cubic-bezier(.19,1,.22,1)});

                @for $i from 1 through 10 {
                    $delay: (0.02s * $i);

                    &:nth-child(#{$i}) {
                        transition-delay: $delay;
                    }
                }

                html:not(.show-nav) & {
                    opacity: 0;
                    transform: translateY(#{u($spacing-unit)});
                }

                + .Nav-item {
                    margin-top: u($spacing-unit--sm);
                }

                .Nav-link {
                    svg {
                        path {
                            stroke: $white;
                            fill: transparent;
                            @include transition(fill);
                        }
                    }
                }
            }
        }

        .Nav-link {
            font-family: $font-roboto-slab;
            position: relative;

            &.Button {
                margin-top: -9px;

                &:hover {
                    border: u(1) solid $cc-dark-grey !important;
                    color: $cc-dark-grey !important;
                }
            }

            svg {
                @include dimensions(16);
            }

            .Counter {
                @include dimensions(16);
                @include position(absolute);
                bottom: 20px;
                right: -9px;
                border-radius: 50%;
                color: $white;
                background-color: $cc-primary;
                line-height: u(16);
                text-align: center;
                font-family: $font-roboto-slab;
                font-weight: 800;
                font-size: u(12);
            }

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $cc-primary!important;

                svg {
                    path {
                        fill: $white;
                    }
                }
            }

            &.Button {
                &:active,
                &:focus,
                &:hover,
                &.is-active {
                    color: $cc-primary !important;
                    background-color: white;
                }
            }
        }

        @include mq($from: $viewport--md) {

            .Nav-list {
                margin-left: u($spacing-unit--sm * -1);
            }

            .Nav-item {
                padding-left: u(35);
            }
        }
    }

    // Footer
    &--footer {

        .Nav-list {
            margin-left: u(0);
        }

        .Nav-item {
            padding-left: u($spacing-unit--sm * 2);
            padding-right: u($spacing-unit--sm * 4);
            width: 50%;
            float: left;
            margin-bottom: u($spacing-unit--sm / 2);

            @include mq($until: $viewport--lg) {
                // width: 100%;
            }

            &::before {
                left: 0;
                background-color: $white;
                top: 8px;
            }
        
            .Nav-link {
                color: $white;
                text-transform: capitalize;
            }
        }
    }
}
