.FutureColleagues {
    background-color: $cc-dark-grey;
    padding-top: u(120);

    &--header {
        h2 {
            color: $white;
            line-height: u(40);
            margin-bottom: u(50);
            max-width: u(430);
        }
    }

    &--card {
        background-color: $cc-dark-grey;
        text-decoration: none;
        
        &-avatar {
            overflow: hidden;
            position: relative;

            img {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }

            &:hover,
            &:active {
                &::before {
                    background-color: rgba($cc-dark-blue, 0);
                }
            }

            &::before {
                @include transition(background-color);
                background-color: rgba($cc-dark-blue, 0.65);
                content: "";
                height: 101%;
                left: 0;
                position: absolute;
                top: 0;
                width: 101%;
            }
        }

        &-info {
            bottom: u(10);
            color: $cc-light-grey;
            font-size: u(15);
            font-weight: 300;
            left: u(20);
            padding: u(30) u(10) u(0);
            position: absolute;

            strong {
                color: $white;
                font-size: u(22);
                font-weight: 500;
            }
        }
    }
}