/* General */

// LazySizes
.LazyLoad,
.LazyLoading {
    opacity: 0;
}

.LazyLoaded {
    @include transition(opacity, 300ms);
    opacity: 1;
}

.RichText {
    padding-top: u(60);
    padding-bottom: u(60);
}

.Content--block {
    min-height: u(400);
}

.Form {
    max-width: u(475);
    width: 100%;

    .Form-label {
        color: $white;
    }

    .Form-controls {

        .Form-field {
            width: 100%;

            &.input {
                height: u(40);            
            }

            &.textarea, &.input {
                @include transition(#{border, color});
                padding: u(5, 10);
                background-color: transparent;
                border: none;
                border-bottom: 1px solid rgba($white, 0.4);
                
                color: rgba($white, 0.4);

                &::placeholder {
                    font-style: italic;
                    color: rgba($white, 0.4);
                }

                &:focus {
                    border-bottom: 1px solid $white;
                    color: $white;

                    &::placeholder {
                        color: $white;
                    }
                }
            }

            &.textarea {
                margin-top: 10px;
                padding: 0 u(10) u(10) u(10);
                height: 100px;
                resize: none;
            }
        }
    }
        
    .Button {
        // width: 100%;
        background-color: $cc-primary;
        color: $white;
    }

    
      
      
}

.Modal {
    &-innerBody {
        max-width: u(600);
        width: 100%;
    }

    &-header {
        height: 0px;
        position: relative;
        padding: 0px;
        border: none;
    }
    

    &-body {
        display: flex;
        position: relative;
        padding: 0;

        @include mq($until: $viewport--md) {
            flex-direction: column;
        }
    }

    &-image {
        position: relative;
        max-width: 240px;

        @include mq($until: $viewport--md) {
            max-width: 100%;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    &-text {
        background-color: $cc-dark-blue;
        padding: u(50);

        h2 {
            color: $cc-primary;
            max-width: 310px;
            width: 100%;
            font-size: 22px;
            font-weight: 500;
        }
    }

    .Location-search {
        @include position(relative);
        max-width: 200px;
        height: u(40);
        overflow: hidden;

        &::after {
            @include position(absolute);
            @include dimensions(40);
            content: url('/assets/img/icons/location.svg');
            background-color: $cc-light-grey;
            display: block;
            top: -3px;
            left: 0;
        }

        #locationCookieAutocomplete {
            width: 100%;
            height: u(40);
            padding: 0 u(20) 0 u(60);
            border: none;
    
            &:active,
            &:focus {
                outline: none;
            }
        }
    }
}
















