/* Nav */
.#{$nav-namespace}Nav {

    // Navigation List
    &-list {
        margin: 0;
        padding: 0;
        list-style: none;

        display: flex;
        flex-wrap: wrap;
        flex: 0 1 auto;
    }

    &-item {
        margin: 0;
    }

    &-link {
        user-select: none;
        display: block;
        font-size: u(13);
        text-transform: uppercase;
        font-weight: 500;
        letter-spacing: u(0.43);

        &,
        &:active,
        &:focus,
        &:hover {
            text-decoration: none;
        }
    }

    // Navigation Toggle
    &-toggle {
        cursor: pointer;
        padding: u($spacing-unit--sm);
        margin-right: u($spacing-unit--sm * -1);
 
        .Toggle-icon {
            display: block;
            fill: $cc-dark-grey;
            width: 25px;
            height: 25px;;
        }
 
       path {
            @include transition(#{ transform, opacity });
            transform: rotate(0deg);
            fill: $cc-dark-grey;
 
            &:nth-child(1) { transform-origin: 2px 6px; }
            &:nth-child(3) { transform-origin: 2px 12px; }
 
            .show-nav & {
                &:nth-child(1) { transform: rotate(45deg); }
                &:nth-child(2) { opacity: 0; }
                &:nth-child(3) { transform: rotate(-45deg); }
            }
        }
    }

    // Navigation Stacked
    &--stacked {

        .#{$nav-namespace}Nav-list {
            flex-direction: column;
            flex-wrap: nowrap;
        }
    }

    // Navigation fit
    &--fit {

        // Apply when stacked modifier is not chained
        &:not(.#{$nav-namespace}Nav--stacked) {

            .#{$nav-namespace}Nav-item {
                flex: 1 1 auto;
            }
        }
    }

    // Alignment
    &--alignCenter {
        text-align: center;

        // Apply when stacked or fit modifier is not chained
        &:not(.#{$nav-namespace}Nav--stacked):not(.#{$nav-namespace}Nav--fit) {

            .#{$nav-namespace}Nav-list {
                justify-content: center;
            }
        }
    }

    &--alignRight {
        text-align: right;

        // Apply when stacked or fit modifier is not chained
        &:not(.#{$nav-namespace}Nav--stacked):not(.#{$nav-namespace}Nav--fit) {

            .#{$nav-namespace}Nav-list {
                justify-content: flex-end;
            }
        }
    }
}
