/* Logo */
.Logo {
    flex-shrink: 0;
    margin-right: auto;

    svg {
        display: block;
        width: u(185px);

        .textLogoHeader{
            fill: #282828;
        }
    }

    img {
        display: block;
        height: u(120px);
    }
}
