.NewsGridWrapper {
    display: flex;
    min-height: 100vh;

    .NewsGrid {
        @include grid(1fr 1fr 1fr, 1fr 1fr 1fr 1fr);
        background-color: $cc-dark-blue;
        height: 100% !important;
        min-height: 100vh;
        position: relative !important;
    
        @include mq($until: $viewport--lg - 1) {
            display: flex !important;
            flex-direction: column !important;
     
            &::before {
                display: none !important;
            }
         }
    
        &--small {
            @include grid(1fr 1fr 1fr, 1fr 1fr);
        }
    
        &--medium {
            @include grid(1fr 1fr 1fr, 1fr 1fr 1fr);
        }
    
        &--length-1 {
            > *:nth-child(7n+1) {
                @include grid-area(1, 4, 1, 3);
            }
        }
    
        &--length-2 {
            > *:nth-child(7n+1) {
                @include grid-area(1, 3, 1, 3);
            }
    
            > *:nth-child(7n+2) {
                @include grid-area(3, 3, 1, 3);
            }
        }
    
        &--length-3 {
            > *:nth-child(7n+1) {
                @include grid-area(1, 3, 1, 3);
            }
    
            > *:nth-child(7n+2) {
                @include grid-area(3, 3, 1, 3);
            }
        
            > *:nth-child(7n+3) {
                @include grid-area(3, 3, 2, 3);
            }
        }
    
        &--length-4 {
            > *:nth-child(7n+1) {
                @include grid-area(1, 3, 1, 3);
            }
    
            > *:nth-child(7n+2) {
                @include grid-area(3, 3, 1, 3);
            }
        
            > *:nth-child(7n+3) {
                @include grid-area(1, 3, 3, 3);
            }
    
            > *:nth-child(7n+4) {
                @include grid-area(3, 4, 3, 3);
            }
        }
    
        &--length-5 {
            > *:nth-child(7n+1) {
                @include grid-area(1, 3, 1, 3);
            }
        
            > *:nth-child(7n+2) {
                @include grid-area(3, 3, 1, 1);
            }
        
            > *:nth-child(7n+3) {
                @include grid-area(3, 3, 2, 2);
            }
        
            > *:nth-child(7n+4) {
                @include grid-area(1, 1, 3, 4);
            }
    
            > *:nth-child(7n+5) {
                @include grid-area(2, 4, 3, 3);
            }
        }
    
        &--length-6 {
            > *:nth-child(7n+1) {
                @include grid-area(1, 3, 1, 3);
            }
        
            > *:nth-child(7n+2) {
                @include grid-area(3, 3, 1, 1);
            }
        
            > *:nth-child(7n+3) {
                @include grid-area(3, 3, 2, 2);
            }
        
            > *:nth-child(7n+4) {
                @include grid-area(1, 1, 3, 4);
            }
    
            > *:nth-child(7n+5) {
                @include grid-area(1, 2, 4, 5);
            }
    
            > *:nth-child(7n+6) {
                @include grid-area(2, 4, 3, 5);
            }
        }
    
        &::before {
            @include grid-area(1, 1, 1, 1);
            content: '';
            padding-bottom: 100%;
            width: 0;
        }
          
        > *:nth-child(7n+1) {
            @include grid-area(1, 3, 1, 3);
        }
    
        > *:nth-child(7n+2) {
            @include grid-area(3, 3, 1, 1);
        }
    
        > *:nth-child(7n+3) {
            @include grid-area(3, 3, 2, 2);
        }
    
        > *:nth-child(7n+4) {
            @include grid-area(1, 1, 3, 4);
        }
    
        > *:nth-child(7n+5) {
            @include grid-area(2, 3, 3, 4);
        }
    
        > *:nth-child(7n+6) {
            @include grid-area(3, 3, 3, 5);
        }
    
        > *:nth-child(7n+7) {
            @include grid-area(1, 3, 4, 5);
        }
    
        .NewsGrid--item {
            @include position(relative);
            color: $white;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            overflow: hidden;
            text-decoration: none;

            @include mq($until: $viewport--lg - 1) {
                height: u(350)
            }
    
            @include mq($until: $viewport--md) {
                height: 100vw;
            }
    
            &:hover {
                &::after {
                    opacity: 0;
                }
            }

            &::after {
                @include position(absolute);
                @include transition(opacity);
                background-image: linear-gradient(to top, rgba($cc-dark-blue, 1), rgba($cc-dark-blue, 0.2));
                content: "";
                display: block;
                height: 100%;
                width: 100%;
                z-index: 1;
            }
    
            .NewsGrid--item-title {
                bottom: 0;
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: flex-end;
                overflow: hidden;
                position: relative;
                z-index: 2;
    
                h2 {
                    margin-bottom: u(10);
                }
            }
    
            .NewsGrid--item-tags {
                z-index: 2;
                font-size: u($font-size / 4 * 3.5);
                font-weight: 500;
            }
    
            picture {
                height: 100% !important;
                position: absolute !important;
                width: 100% !important;
            }
    
            img {
                height: 100% !important;
                left: 0 !important;
                object-fit: cover;
                width: 100% !important;

                @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                    height: auto !important;
                }
            }
        }
    }
}