.ui-menu {
    .ui-menu-item {
        padding:  u($line-height / 4) u($line-height / 3);

        .ui-menu-item-wrapper {
            &.ui-state-active {
                background: $cc-grey;
                border-top: u(1) solid $cc-grey;
                border: none;
                color: $white;
            }
        }
    }

    &.ui-widget {
        &.ui-widget-content {
            border: none;
        }
    }

    .ui-menu-item {
        span {
            font-weight: 600;
            padding: 0 !important;
        }

        &::before, &::after {
            display: none;
        }
    }
}