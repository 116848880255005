.InfoTiles {
    background-color: $cc-light-grey;
    padding-bottom: u(76);
    padding-top: u(100);

    .InfoTiles--item {
        margin-bottom: u($line-height * 2);
        
        .InfoTiles--item-content {
            border: u(1) solid $cc-grey;
            display: flex;
            flex-direction: row;
            height: 100%;

            @include mq($until: $viewport--sm) {
                flex-direction: column;
            }

            .InfoSection--item-image {
                width: 50%;
                height: 100%;

                @include mq($until: $viewport--sm) {
                    height: u(200);
                    width: 100%;
                }

                img {
                    height: 100% !important;
                    object-fit: cover;
                    width: 100%;
                }
            }

            .InfoSection--item-text-wrapper {
                align-items: center;
                display: flex;
                width: 50%;

                @include mq($until: $viewport--sm) {
                    width: 100%;
                }

                .InfoSection--item-text {
                    padding: u($spacing-unit * 1.5);
                    width: 100%;
    
                    h1, h2, h3, h4, h5, h6 {
                        font-size: u($line-height / 1.5);
                        margin-bottom: u($line-height / 2);
                    }
                    p {
                        font-size: u($font-size);
                        margin-bottom: u($font-size);
                    }
    
                    a {
                        font-weight: 600;
                        text-decoration: none;
                    }
                }
            }
            
        }
    }
}