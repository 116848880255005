.JobAlerts {
    background-color: $cc-light-grey;
    
    .Container {
        align-items: center;
        display: flex;
        flex-direction: column;
        padding-top: u(65);

        h2 {
            color: $cc-primary;
            font-size: 20px;
            font-weight: 500;
            margin: 20px;
            text-align: center;
        }

        .Form {
            margin-top: u(25);
            max-width: u(475);
            padding-bottom: u(100);
            width: 100%;

            .Button {
                &.Button--primary {
                    &:active,
                    &:hover {
                        border-color: $cc-dark-grey;
                        color: $cc-dark-grey;
                    }
                }
            }

            .Form-label {
                color: $cc-primary;
                font-weight: 500;
                margin-bottom: u(15);
            }

            .Form-controls {
                .Form-field {
                    margin-bottom: 10px;

                    &.input {
                        border-bottom: u(1) solid $cc-grey;
                        color: $cc-grey;

                        &::placeholder {
                            color: $cc-grey;
                        }
        
                        &:focus {
                            border-bottom: u(1) solid $cc-dark-grey;
                            color: $cc-dark-grey;
                        }
                    }
                    
                    .Form-field--checkbox {
                        font-weight: 300;
                    }
                }
            }
    
            .Form-field--radio .Form-indicator, .Form-field--checkbox .Form-indicator {
                background-color: $cc-light-grey;
            }
    
            .Form-field--radio input[type="radio"]:checked ~ .Form-indicator, .Form-field--radio input[type="checkbox"]:checked ~ .Form-indicator, .Form-field--checkbox input[type="radio"]:checked ~ .Form-indicator, .Form-field--checkbox input[type="checkbox"]:checked ~ .Form-indicator {
                background-color: $cc-primary;
                border-color: $cc-primary;
            }
        }
    }
}