.Nav--pagination {

    &-news {
        border: u(1) solid $cc-primary;
        border-left: none;
        margin-top: u(63);
        margin-bottom: u(63);

        .Nav-item {
            height: u(40);
            min-width: u(30);
            border-left: u(1) solid $cc-primary;
            display: flex;
            justify-content: center;
            align-items: center;

            .Nav-link {
                color: $cc-primary;
                height: 100%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                &.is-active {
                    color: $white;
                    background-color: $cc-primary;;
                }

            }

            &.has-arrow {
                border-left: none;

                &.is-first {
                    border-left: u(1) solid $cc-primary;
                    border-right: u(1) solid rgba($cc-primary, 0.5);
    
                    + .Nav-item {
                        border-left: none;
                    }
                }

                &.is-last {
                    border-left: u(1) solid rgba($cc-primary, 0.5);
                }
            }
        }
    }

    ul {
        display: flex;
        justify-content: center;
        width: 100%;

        .Nav-item {
            &::before {
                display: none;
            }



            .Button {
                border-color: $cc-grey;
                color: $cc-grey;
                font-family: $font-roboto-slab;
                min-width: u(100);
            }
        }
    }
}