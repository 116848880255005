
.Blog--intro {
    font-size: u(20);
    font-style: normal !important;
    font-weight: 600 !important;
    margin-top: u($line-height * 2);

    strong {
        font-style: normal !important;
        font-weight: 600 !important;
    }

    em {
        font-style: normal !important;
        font-weight: 600 !important;
    }
}

.Blog--blocks-quote {
    border-left: u(2) solid $cc-primary;
    font-size: u(20);
    margin-bottom: u($line-height * 2);
    margin-top: u($line-height * 2);
    padding: u(20) 0 u(20) u(50);
}

.Blog--blocks-image {
    margin-bottom: u($line-height * 2);
}

.Blog--tags {
    display: flex;
    margin-top: u(60);

    .Blog--tag {
        border: u(1) solid $cc-grey;
        margin-right: u(20);
        padding: u(5) u(15);
    }
}

.Blog--share {
    margin-top: u(60);
    margin-bottom: u(120);

    .Button {
        width: u(60);
        border-radius: u(20);
        padding-left: u(10);
        padding-right: u(10);

        &.facebook,
        &.twitter {
            path {
                stroke: none;
                fill: $cc-grey;
            }

            &:hover {
                path {
                    stroke: none;
                    fill: $white;
                }
            }
        }

        svg {
            margin: 0;
            path {
                stroke: $cc-grey;
            }
        }
    }
}
