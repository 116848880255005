.Slider {
    background: $cc-light-grey;
    height: 100%;
    min-height: u(600);

    .Slider-pagination {
        bottom: u(27);

        .Slider-pagination-item {
            @include dimensions(10);
            @include transition(opacity);
            background: $white;
            border-width: u(1);
            margin: 0 u($spacing-unit / 2);
            opacity: 1;
            
            &:hover {
                opacity: 0.8;
            }

            &.is-active {
                background-color: transparent;
                border: u(1) solid $white;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .Slider-items {
        .Slider-item {
            @include position(relative);

            .Slide-item--text {
                @include position(absolute, $top: 50%);
                color: $white;
                transform: translateY(-50%);
                width: 100%;
                z-index: 2;

                &-title {
                    margin-bottom: u(25);
                }
            }

            .Slider-item--image {
                height: 100% !important;
                width: 100% !important;

                &::before {
                    @include position(absolute);
                    background-color: $cc-dark-blue;
                    content: "";
                    display: block;
                    height: 100%;
                    opacity: 0.5;
                    width: 100%;
                    z-index: 1;
                }
            }

            img {
                @include position(absolute);
                height: 100% !important;
                margin: 0 !important;
                object-fit: cover;
                top: 0 !important;
                width: 100% !important;
            }
        }
    }
}