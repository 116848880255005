.Vacancy-detail {
    margin-top: u(120);

    > * {
        font-weight: 300;
        line-height: u(25);
    }

    .Vacancy-description {
        h3 {
            color: $cc-primary;
            display: inline-block;
            font-size: u(30);
            font-weight: 500;
            letter-spacing: u(0.5);
            margin-top: u(40);
    
            @include mq($until: $viewport--sm) {
                font-size: u(24);
            }
        }
    }

    &--map {
        height: u(236);
        margin-bottom: u(120);
        margin-top: u(40);

        @include mq($until: $viewport--xl - 1) {
            margin-bottom: u(50);
        }
    }

    &--metadata {
        background-color: $cc-light-grey;
        margin-bottom: u(50);
        padding: u(50) u(65);

        div {
            margin-bottom: u(5);
        }

        &-icon {
            width: u(30);
        }

        &-text {
            margin-left: u(15);
        }
        
    }

    &--share {
        background-color: $cc-light-grey;
        margin-bottom: u(50);
        padding: u(35) u(65) u(65) u(65);

        @include mq($until: $viewport--xl - 1) {
            margin-bottom: u(120);
        }

        a {
            border: u(1) solid $cc-light-grey;
            display: block;
            float: left;
            height: u(40);
            margin-right: u(5);
            position: relative;
            text-decoration: none;
            width: u(40);

            &:first-of-type {
                margin-left: u(-10);
            }
             

            &:hover {
                @include transition(border);
                border: u(1) solid $cc-primary;
            }

            svg {
                height: u(22);
                left: 50%;
                position: absolute;
                top: 50%;
                transform: translate(-50%, -50%);
                width: u(22);
            }
        }
    }

    &--contact {
        border: u(1) solid $cc-grey;
        margin-bottom: u(50);
        padding: u(50) u(65);

        &-info-wrapper {
            display: flex;
            margin-bottom: u(16);
        }

        &-avatar {
            @include dimensions(58);
            margin-right: u(16);
        }

        &-text {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            min-height: u(58);   

            strong {
                font-weight: 700;
            }
        }

        a {
            display: block;
            line-height: u(20);
            margin-bottom: u(10);
            margin-left: 0;
            text-decoration: none;
            width: 100%;

            &.LinkedIn {
                svg {
                    height: u(15);
                    margin-bottom: u(8);
                    width: u(20);
                }
            }

            svg {
                height: u(20);
                margin-right: u(10);
                width: u(20);
            }  
        }
    }

    &--jobalerts {
        background-color: $cc-grey;
        color: $white;
        margin-bottom: u(50);
        padding: u(50) u(40) u(90) u(65);

        &-form {
            margin-top: u(15);

            .Form-item {
                float: left;
                width: 60%;

                &.Form-item--action {
                    width: 40%; 
                }

                .Form-label {
                    display: none;
                }
                
                .input {
                    height: u(28);
                    padding: 0 u(20);
                    width: 100%;
                }

                .Button {
                    background-color: $cc-primary;
                    color: $white;
                    font-weight: 400;
                    height: u(40);
                    width: 100%;

                    &:hover {
                        background-color: $white;
                        color: $cc-primary;
                    }
                }
            }
        }
    }
}