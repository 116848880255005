.ImageWithText {
    &.is-reversed {
        .Grid {
            @include mq($until: $viewport--lg) {
                flex-direction: column-reverse;
            }
        }

        .Grid-cell {
            justify-content: flex-end;

            @include mq($until: $viewport--lg) {
                &:first-of-type {
                    order: 0;
                }
            }

            &.ImageWithText--image {
                justify-content: flex-start;
            }

            &:first-of-type {
                order: 1;
            }

            > .Container {
                margin: 0;
                max-width: calc(#{$structure-width--xl}px / 2);
                padding-left: 0 !important;
                padding-right: u(120) !important;
                width: 100%;
    
                @include mq($until: $viewport--md) {
                    padding-left: u(20) !important;
                    padding-right: u(20) !important;
                }
    
                @include mq($until: $viewport--xl) {
                    max-width: 100%;
                    padding-left: u(40) !important;
                }
            }
        }
    }

    .Grid-cell {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        max-height: u(700);
        overflow: hidden;
        position: relative;

        @include mq($until: $viewport--lg) {
            min-height: u(400);

            &:last-of-type {
                min-height: auto !important;
            }
        }

        @include mq($until: $viewport--md) {
            min-height: u(400);

            .ImageWithText--image {
                min-height: u(400);
            }
        }

        @include mq($until: $viewport--sm) {
            min-height: u(250);

            .ImageWithText--image {
                min-height: u(250);
            }
        }

        &.ImageWithText--image {
            justify-content: flex-start;
        }

        &:first-of-type {
            align-items: flex-start;
        }

        > .Container {
            margin: 0;
            max-width: calc(#{$structure-width--xl}px / 2);
            padding-left: u(100) !important;
            padding-right: u(60) !important;
            width: 100%;

            @include mq($until: $viewport--md) {
                padding-left: u(20) !important;
                padding-right: u(20) !important;
            }

            @include mq($until: $viewport--xl) {
                max-width: 100%;
                padding-left: u(40) !important;
                padding-right: u(40) !important;
            }
        }        

        .ImageWithText--text {
            h1, h2, h3, h4, h5, h6 {
                font-size: u(32);
                line-height: 1.2;
                margin-bottom: u(20);
            }

            a {
                cursor: pointer;
                font-weight: 600;
                text-decoration: none;
            }

            p {
                &:last-of-type {
                    margin-bottom: 0;
                }
            }
        }

        picture {
            height: 100% !important;
            width: 100% !important;
        } 
        
        img {
            height: 100% !important;
            margin: 0 !important;
            object-fit: cover;
            position: relative !important;
            top: 0 !important;
            width: 100% !important;
        }
    }
}