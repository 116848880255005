.CallToAction {
    @include position(relative);
    height: u(375);
    overflow: hidden;

    @include mq($until: $viewport--lg) {
        height: u(375);
    }

    &.Secondary {
        background-color: $cc-secondary;

        .Button--primary {
            background-color: $black;
            color: $cc-secondary;

            svg {
                path {
                    stroke: $cc-secondary;
                }
            }

            &:hover,
            &:active {
                background-color: transparent;
                border: u(1) solid $black;
                color: $black;

                svg {
                    path {
                        stroke: $black;;
                    }
                }
            }
        }

        .CallToAction--image {
            &::before {
                @include position(absolute);
                background-color: $cc-secondary;
                content: "";
                display: block;
                height: 100%;
                opacity: 0.8;
                width: 100%;
                z-index: 1;
            }
        }

        .Container {
            .CallToAction--title {
                color: $black;
            }
        }
    }

    .Container {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        z-index: 1;

        .CallToAction--title {
            color: $white;
            display: block;
            margin-bottom: u(30);
            width: 100%;
        }
    }    

    .CallToAction--image {
        height: auto !important;
        position: static !important;

        &::before {
            @include position(absolute);
            background-color: $cc-primary;
            content: "";
            display: block;
            height: 100%;
            opacity: 0.8;
            width: 100%;
            z-index: 1;
        }
        
        img {
            @include position(absolute);
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
    }
}