.IconsSection {
    padding: u(60) 0;

    .IconsSection--item {
        text-align: center;

        @include mq($until: $viewport--lg - 1) {
            margin-bottom: u($line-height * 2);

            &:nth-last-child(2), &:nth-last-child(1) {
                margin-bottom: 0;
            }
        }

        @include mq($until: $viewport--md - 1) {
            margin-bottom: u($line-height * 2);
            
            &:nth-last-child(2) {
                margin-bottom: u($line-height * 2);
            }

            &:nth-last-child(1) {
                margin-bottom: 0;
            }
        }

        .IconsSection--item-icon {
            span {
                color: $cc-primary;
                font-size: u(60);
            }
        }

        .IconsSection--item-title {
            h5 {
                color: $cc-primary;
                font-size: u(17);
                margin-bottom: u($line-height / 2);
                margin-top: u($line-height);
            }
        }

        .IconsSection--item-text {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;

            p {
                width: 100%;

                @include mq($until: $viewport--md) {
                    max-width: u(250);
                }
            }
        }
    }
}