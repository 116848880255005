/* Fields: file */
.#{$form-namespace}Form-field--file {
    cursor: pointer;
    display: block;

    input[type='file'] {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;

        // UI state
        // &:focus ~ .#{$form-namespace}Form-indicator {
        //     border-color: $form-element-border-color--focus;
        //     box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-element-box-shadow--focus);

        //     // Error
        //     .#{$form-namespace}Form-item.is-error & {
        //         box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-error-element-box-shadow);
        //     }

        //     // Success
        //     .#{$form-namespace}Form-item.is-success & {
        //         box-shadow: if(is-false($form-element-box-shadow), null, $form-element-box-shadow) if(is-false($form-element-box-shadow) or is-false($form-element-box-shadow--focus), null, #{','}) if(is-false($form-element-box-shadow--focus), null, $form-success-element-box-shadow);
        //     }
        // }
    }

    .#{$form-namespace}Form-indicator {
        @include font-smoothing($font-smoothing);

        color: rgba($white, 0.5);
        display: block;
        margin-top: 15px;
        line-height: 40px;
        overflow: hidden;
        padding: 0 10px;
        position: relative;
        white-space: nowrap;
        width: 100%;
        height: 40px;
        @include transition(color);

        &::before {
            @extend .u-textTruncate !optional;
            content: attr(data-file-text);
            display: block;
            padding-right: u($form-file-element-button-width);
        }

        &::after {
            @include transition(#{background-color, border});
            background-color: $cc-primary;
            color: $white;
            border: u(1) solid transparent;
            content: attr(data-file-button);
            font-weight: 500;
            padding: 0 25px;
            text-align: center;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
        }

        &:hover,
        &:active {
            color: rgba($white, 1);

            &::after {
                background-color: transparent;
                border: u(1) solid $white;
                color: $white;
            }
        }
    }


    // Fixed or fluid width
    // @if is-false($form-element-fluid) and not is-null($form-element--default) {
    //     @include mq($from: $viewport--sm) {
    //         max-width: u($form-element--default);
    //     }
    // }
}