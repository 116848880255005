/* List */
ol,
ul {
    margin-bottom: u($spacing-unit);
    padding-left: u($list-gutter--l * 2);

    li {
        margin-bottom: u(2);
    }

    ol,
    ul {
        margin-top: u(4);
        margin-bottom: u(4);
    }
}

// Unordered List
ul {
    list-style-type: none;

    li {
        position: relative;

        &::before {
            content: "";
            height: 3px;
            width: 3px;
            background-color: $cc-primary;
            border-radius: 50%;
            position: absolute;
            display: block;
            top: 10px;
            left: -30px;
        }
    }
}

ul ul {
    list-style-type: circle;

    li {
        &::before {
            display: none;
        }
    }
}

ul ul ul {
    list-style-type: square;

    li {
        &::before {
            display: none;
        }
    }
}

// Ordered List
ol {
    list-style-type: decimal;
}

ol ol {
    list-style-type: upper-alpha;
}

ol ol ol {
    list-style-type: lower-roman;
}

ol ol ol ol {
    list-style-type: lower-alpha;
}

// Definition List
dt {
    font-weight: 700;
}