.Stages {
    background-color: $cc-light-grey;
    overflow: hidden;
    padding-bottom: u(180);
    padding-top: u(80);

    .Timeline {
        padding-top: u(60);
        position: relative;

        &::before {
            background-color: $cc-light-grey;
            border-radius: 50%;
            border: u(2) solid $cc-primary;
            content: "";
            height: u(26);
            left: 50%;
            padding: u(4);
            position: absolute;
            top: u(60);
            transform: translateX(-50%);
            width: u(26);
            z-index: 1;

            @include mq($until: $viewport--md) {
                left: 0;
                transform: none;
            }
        }

        &::after {
            background-color: $cc-primary;
            content: "";
            height: 100%;
            left: 50%;
            position: absolute;
            top: u(60);
            transform: translateX(-50%);
            width: u(2);

            @include mq($until: $viewport--md) {
                left: u(12);
                transform: none;
            }
        }

        .Grid-cell {
            &:first-of-type {
                .Stages--item {
                    &::before {
                        @include dimensions(14);
                        background-color: $cc-dark-grey;
                        border-radius: 50%;
                        content: "";
                        left: u(-27);
                        position: absolute;
                        top: u(6);
                        z-index: 1;

                        @include mq($until: $viewport--md) {
                            left: u(6);
                        }
                    }
                }  
            }
    
            &:nth-of-type(2n+1) {
                .Stages--item {
                    padding-left: u(80); 

                    @include mq($until: $viewport--md) {
                        padding-left: u(40); 
                    }

                    &::after {
                        left: u(-27);

                        @include mq($until: $viewport--md) {
                            left: u(6);
                        }
                    }
                }
            }
    
            &:nth-of-type(2n+2) {
                .Stages--item {
                    align-items: flex-end;
                    padding-right: u(80); 
                    text-align: right;

                    @include mq($until: $viewport--md) {
                        align-items: flex-start;
                        padding-left: u(40); 
                        padding-right: u(0);
                        text-align: left;

                        &::after {
                            left: u(6);
                            right: auto;
                        }
                    }

                    &::after {
                        right: u(-27);
                    }
                }
            }

            .Stages--item {
                display: flex;
                flex-direction: column;
                padding-top: u(45);
                position: relative;

                &::after {
                    @include dimensions(14);
                    @include transition(background-color);
                    background-color: $cc-primary;
                    border-radius: 50%;
                    content: "";
                    position: absolute;
                    top: 70%;
                    transform: translateY(-50%);
                    z-index: 1;
                }

                &:hover {
                    &::after {
                        background-color: $cc-main-blue;
                    }
                }

                &-title {
                    font-weight: 500;
                    margin-bottom: u(15);
                    max-width: u(250);
                }

                svg {
                    height: u(50);
                    margin-bottom: u(15);
                }

                &-text {
                    max-width: u(250);

                    p {
                        &:last-of-type {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}