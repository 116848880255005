.Banner {
    @include position(relative);
    height: u(400);
    overflow: hidden;

    &--large {
        height: u(648);
    }

    .Container {
        @include position(absolute);
        height: 100%;
        left: 50%;
        top: 0;
        transform: translateX(-50%);
        width: 100%;
        z-index: 1;

        > .Grid {
            @include position(absolute);
            bottom: u(80);
            width: calc(100% - 80px);
            z-index: 1;

            @include mq($until: $viewport--md - 1) {
                width: calc(100% - 40px);
            }
        }

        .Banner--title {
            color: $white;

            p {
                margin-bottom: u(10);

                &:last-of-type {
                    margin-bottom: 0;;
                }
            }
        }

        .Banner--form {
            &.Form {
                max-width: 100%;

                .Form-item {
                    margin-bottom: u($line-height / 2);
        
                    .Form-controls {
                        .FakeSelect {
                            @include position(relative);
                            display: inline-block;
                            font-size: u(16);
                            width: 100%;

                            &::after {
                                @include position(absolute);
                                background-image: url('/assets/img/icons/icon-arrow-down.svg');
                                background-position: center;
                                background-repeat: no-repeat;
                                content: "";
                                display: block;
                                height: 100%; 
                                right: u(30);
                                top: 0;
                                width: u(10); 

                                @include mq($until: $viewport--sm) {
                                    right: u(15);
                                }
                            }
            
                            select {
                                -webkit-appearance: none;
                                @include transition(#{border, color});
                                background-color: $cc-primary;
                                border-radius: 0;
                                border: u(1) solid $cc-primary;
                                color: $white;
                                display: block;
                                font-family: $font-roboto-slab;
                                font-size: u(15);
                                font-weight: 400;
                                height: u(40);
                                margin: 0;
                                outline: none;
                                padding: 0 u($spacing-unit / 3);
                                padding: u(5, 30);
                                width: 100%;

                                @include mq($until: $viewport--sm) {
                                    padding: 0 u(15);
                                }
                            }
                        }

                        &.search {
                            position: relative;

                            .Form-field {
                                width: calc(100% - 40px);

                                &.input {
                                    &::placeholder {
                                        color: $cc-grey;
                                    }   
                                }
                            }
                            
                            .SearchButton {
                                background-color: $cc-primary;
                                background-image: url('/assets/img/icons/icon-search.svg');
                                background-position: center;
                                background-repeat: no-repeat;
                                cursor: pointer;
                                height: u(40);
                                position: absolute;
                                right: 0;
                                top: 0;
                                width: u(40);
                            }
                        }

                        &.location {
                            position: relative;

                            &::after {
                                background-color: $cc-light-grey;
                                background-image: url('/assets/img/icons/icon-marker.svg');
                                background-position: center;
                                background-repeat: no-repeat;
                                content: "";
                                display: block;
                                height: u(38);
                                left: u(1);
                                position: absolute;
                                top: u(1);
                                width: u(39);
                            }

                            .Form-field {
                                &.input {
                                    padding-left: u(70);

                                    @include mq($until: $viewport--sm) {
                                        padding-left: u(55);
                                    }

                                    &::placeholder {
                                        color: $cc-grey;
                                    }   
                                }
                            }
                        }
            
                        .Form-field {
                            width: 100%;
                            
                            &.input {
                                @include transition(#{font-weight, color});
                                background-color: $white;
                                color: $cc-grey;
                                font-family: $font-roboto-slab;
                                font-size: u(15);
                                font-weight: 400;
                                height: u(40);            
                                padding: u(5, 30);
                                position: relative;

                                @include mq($until: $viewport--sm) {
                                    padding: u(5, 15);
                                }
                
                                &:focus {
                                    color: $cc-dark-blue;
                                    font-weight: 600;
                
                                    &::placeholder {
                                        color: $cc-dark-blue;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .Button-wrapper {
            margin-bottom: u(55);
        }

        .Button {
            margin-bottom: u(15);
            margin-right: u(15);

            &.Button--outline {
                &:hover,
                &:active {
                    border-color: $white;
                    color: $cc-dark-grey;

                    svg {
                        path {
                            stroke: $cc-dark-grey;
                        }

                        polygon {
                            stroke: $cc-dark-grey;
                        }
                    }
                }
            }
        }
    }

    &::before {
        @include position(absolute);
        background-color: $cc-dark-blue;
        content: "";
        display: block;
        height: 100%;
        opacity: 0.3;
        width: 100%;
        z-index: 1;
    }

    img {
        @include position(absolute);
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}