.StickyVacancyBar {
    background-color: $cc-dark-grey;
    color: $white;
    padding: u(25) 0;
    position: fixed;
    top: 0;
    transition: transform 200ms linear;
    width: 100%;
    will-change: transform;
    z-index: 10;

    .Container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        @include mq($until: $viewport--lg) {
            align-items: flex-start;
            flex-direction: column;
            justify-content: flex-start;

            .Button {
                display: inline-block;
            }
        }
    }

    &--text {
        align-items: flex-end;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        @include mq($until: $viewport--lg) {
            align-items: flex-start;
            flex-direction: column;
            margin-bottom: u(20);

            h2 {
                margin-bottom: u(10) !important;
            }
        }

        h2 {
            line-height: 1;   
            margin-bottom: 0;
            margin-right: u(20);
        }

        span {
            display: inline-block;
            margin-bottom: u(0);
        }
    }

    @include mq($until: $viewport--md) {
        bottom: 0;
        top: auto;

        &--pinned {
            opacity: 1;
            transform: translateY(0%) !important;
        }
    
        &--unpinned {
            opacity: 0;
            transform: translateY(100%) !important;
        }
    }
    
    &--pinned {
        transform: translateY(0%);
    }

    &--unpinned {
        transform: translateY(-100%);
    }
}