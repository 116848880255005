/* Button */

.Button {
    font-family: $font-roboto-slab;
    font-weight: 600;

    // Primary
    &--primary {
        background-color: $white;
        color: $cc-primary;
        @include transition(all);

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: transparent;
                border: solid 1px $white;
                color: $white;
                outline: none;

                svg {
                    path {
                        stroke: $white;
                    }
                    polygon {
                        stroke: $white;
                    }
                }
            }

            svg {
                margin-left: u($spacing-unit--sm * 1.5);
    
                path {
                    stroke: $cc-primary;
                }
                polygon {
                    stroke: $cc-primary;
                }
            }
        }

        &.Button--outline {
            color: $white;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $cc-primary;
                background-color: $white;

                svg {
                    path {
                        stroke: $cc-primary;
                    }
                    polygon {
                        stroke: $cc-primary;
                    }
                }
            }
        }

        svg {
            margin-left: u($spacing-unit--sm * 1.5);

            path {
                stroke: $white;
            }
            polygon {
                stroke: $white;
            }
        }
    }

    // Secondary
    &--secondary {
        background-color: $cc-grey;
        color: $cc-grey;
        @include transition(all);

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $cc-grey;
                color: $white;
            }
        }

        &.Button--outline {
            color: $cc-grey;
            background-color: transparent;

            &.is-active {

            }

            &:hover {
                background-color: $cc-secondary;
                color: $black;
                border: u(1) solid $cc-secondary;

                svg {
                    path {
                        stroke: $black;
                    }
                    polygon {
                        stroke: $black;
                    }
                }
            }
        }

        svg {
            margin-left: u($spacing-unit--sm * 1.5);

            path {
                stroke: $cc-grey;
            }
            polygon {
                stroke: $cc-grey;
            }
        }
    }

    // Info
    &--info {
        background-color: $blue;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $blue--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $blue;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $blue--700;
            }
        }
    }

    // Success
    &--success {
        background-color: $green;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $green--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $green;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $green--700;
            }
        }
    }

    // Warning
    &--warning {
        background-color: $orange;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $orange--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $orange;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $orange--700;
            }
        }
    }

    // Danger
    &--danger {
        background-color: $red;
        color: $white;

        &:not(.Button--outline) {

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                background-color: $red--700;
                color: $white;
            }
        }

        &.Button--outline {
            color: $red;

            &:active,
            &:focus,
            &:hover,
            &.is-active {
                color: $red--700;
            }
        }
    }

    // Outline
    &--outline {
        background-color: transparent;
        border-color: currentColor;
    }

    // Large
    &--large {
        @include font-size($button-font-size * 1.25, false);
        padding-right: u($button-padding--r * 2);
        padding-left: u($button-padding--l * 2);
    }

    // Small
    &--small {
        @include font-size($button-font-size * .75, false);
        padding-right: u($button-padding--r / 1.5);
        padding-left: u($button-padding--l / 1.5);
    }

    // Full
    &--full {
        display: block;
        width: 100%;
    }

    // Bare
    &--bare {
        border: 0;
        border-radius: 0;
        padding: 0;
    }
}
