.Vacancy-list {
    background-color: $cc-light-grey;
    overflow: hidden;
    padding-bottom: u(120);
    padding-top: u(130);

    h2 {
        margin-bottom: u($spacing-unit * 2.5);
    }

    .Vacancy {
        margin-bottom: u($spacing-unit--lg);

        &--content {
            background-color: $white;
            border: u(1) solid $cc-grey--hover;
            display: block;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: space-between;
            padding: u($spacing-unit--xs * 13, $spacing-unit * 3, $spacing-unit * 3, $spacing-unit * 3);
            text-decoration: none;

            @include mq($until: $viewport--md) {
                padding: u($spacing-unit--xs * 7, $spacing-unit * 1.5, $spacing-unit * 1.5, $spacing-unit * 1.5);
            }
        }
        
        &--header {
            h3 {
                margin-bottom: u(15);
            } 
        }

        .Boosted {
            border-bottom: u(58) solid transparent;
            border-right: u(58) solid $cc-primary; 
            border-top: u(58) solid transparent;
            height: 0; 
            position: absolute;
            right: u(-8);
            top: u(-37);
            transform: rotate(135deg);
            width: 0; 

            svg {
                position: absolute;
                right: u(-43);
                top: u(-10);
                transform: rotate(-135deg);
            }
        }
    
        &--body {
            p:first-of-type {
                display: block;
            }

            p {
                --max-lines: 4;
                display: none;
                margin-bottom: u(15);
                max-height: calc(22px * var(--max-lines));
                overflow: hidden;
                padding-right: u($spacing-unit--xs * 5);
                position: relative;
            }
        }

        &--footer {
            position: relative;
    
            &-likeButton {
                bottom: u(-36);
                cursor: pointer;
                display: flex;
                justify-content: flex-end;
                position: absolute;
                right: u(-36);
                width: u(50);

                @include mq($until: $viewport--md) {
                    bottom: 0;
                    right: 0;
                }

                &:hover {
                    svg {
                        path {
                            stroke: $cc-primary;
                        }
                    } 
                }
    
                svg {
                    @include dimensions(20, 18);
                    cursor: pointer;
                    margin-top: u(5);

                    path {
                        @include transition(#{fill, stroke});
                        fill: $white;
                        stroke-width: 2;
                        stroke: $cc-dark-grey;
                    }
                }

                &.is-liked {
                    svg {
                        path {
                            fill: $cc-primary;
                            stroke: $cc-primary;
                        }
                    }
                }
            }
        }
    }
}